import { render, staticRenderFns } from "./addOccupation.vue?vue&type=template&id=4b597d59&scoped=true&"
import script from "./addOccupation.vue?vue&type=script&lang=js&"
export * from "./addOccupation.vue?vue&type=script&lang=js&"
import style0 from "./addOccupation.vue?vue&type=style&index=0&id=4b597d59&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b597d59",
  null
  
)

export default component.exports