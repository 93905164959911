import request from '@/libs/request'
import { method } from 'lodash'

/**
 * 初始化职位列表数据
 */
export function getOccupationListApi(page, limit) {
    return request({
        url: `/user/userJobList?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

/**
 * 修改职位列表状态
 */
export function editOccupationStatusApi(id) {
    return request({
        url: `/user/userStatusJob?id=${id}`,
        method: 'get'
    })
}

//  新增职位数据初始化

export function addOccupationInitApi() {
    return request({
        url: '/user/userEditJob',
        method: 'get'
    })
}

/**
 * 删除职位列表
 */
export function deleteOccupationListApi(o_id) {
    return request({
        url: `/user/userDeleteJob?id=${o_id}`,
        method: 'get',
    })
}

/**
 * 新添或编辑列表职位列表
 */
export function addOccupationListApi(data) {
    return request({
        url: '/user/userSaveJob',
        method: 'post',
        data
    })
}
/**
 * 编辑状态下数据回显
 */
export function showEditOccupationList(id) {
    return request({
        url: `/user/userReadJob?id=${id}`,
        method: 'get',
    })
}

/**
 * 编辑状态提交数据
 */
export function editOccupationListApi(data) {
    return request({
        url: `/user/userUpdateJob?id=${data.id}`,
        method: 'post',
        data
    })
}

export function collectionApi(id) {
    return request({
        url: `/collection/collAdd/${id}`,
        method: 'get',
    })
}