<template>
  <div class="add-occupation">
    <ul class="c-title">
      <li class="t-text">职位发布信息</li>
      <li>
        <el-button type="info" size="small" @click="$router.go(-1)">返回</el-button>
      </li>
    </ul>
    <div class="m-container" v-if="isShowInfo" v-loading="loading">
      <ul class="c-list">
        <li class="l-img"></li>
        <li class="l-info">
          <h3>职位基本信息</h3>
          <!-- <p>
            职位发布成功后，招聘类型、职位名称、职位类型、工作城市，将无法修改
          </p> -->
        </li>
      </ul>
      <el-form ref="occupationRefs" :model="occupationForm" :rules="occupationRules" label-width="120px" class="mt10">
        <!-- <el-form-item label="公司：">
          <span class="company-name"> {{ company_full }}</span>
        </el-form-item> -->
        <!-- <el-form-item label="招聘类型：" prop="recruit_type">
          <el-select v-model="occupationForm.recruit_type" placeholder="请选择" size="medium" clearable>
            <el-option v-for="(item, index) in recruitType" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="职位名称：" prop="job_name">
          <el-input v-model="occupationForm.job_name" placeholder="请输入职位名称" maxlength="20" show-word-limit
            size="medium"></el-input>
        </el-form-item>
        <!-- <el-form-item label="职业类型：" prop="job_type">
          <el-input
            v-model="occupationForm.job_type"
            placeholder="请输入职位名称"
            size="medium"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="岗位类型：" prop="allocation">
          <el-select v-model="occupationForm.allocation" placeholder="请选择岗位类型" size="medium" clearable>
            <el-option v-for="(item, index) in allocationList" :key="index" :label="item" :value="index">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="招聘人数：" prop="job_num">
          <el-input v-model.number="occupationForm.job_num" placeholder="请输入人数" size="medium" type="number" min="1">
          </el-input>
        </el-form-item>
        <el-form-item label="职位描述：" prop="job_description">
          <el-input v-model="occupationForm.job_description" placeholder="请输入职位描述" maxlength="5000" minlength="3"
            show-word-limit size="medium" type="textarea" :rows="5"></el-input>
        </el-form-item>
        <ul class="c-list" style="margin: 40px 0px 20px">
          <li class="l-imgTwo"></li>
          <li class="l-info">
            <h3>职位要求</h3>
            <!-- <p>我们将通过以下条件，为您精确推荐合适的牛人，请尽量详细填写</p> -->
          </li>
        </ul>
        <!-- <el-form-item label="学历：" prop="education">
          <el-select v-model="occupationForm.education" placeholder="请选择学历" size="medium" clearable>
            <el-option v-for="(item, index) in educationList" :key="index" :label="item" :value="index">
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="工作经验：" prop="work_exp">
          <el-select v-model="occupationForm.work_exp" placeholder="请选择工作经验" size="medium" clearable>
            <el-option v-for="(item, index) in work_exp" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-row>
          <el-col :span="6">
            <el-form-item label="薪资范围：" prop="min_salary_range">
              <el-input v-model.number="occupationForm.min_salary_range" size="medium" type="number" :min="0"
                :max="10000" oninput="value=value.replace(/[^\d]/g,'')" style="width:100%" class="elhide">
                <i slot="suffix">k</i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1" style="text-align:center;line-height: 36px;" class="elhide">~</el-col>
          <el-col :span="6">
            <el-form-item label-width="0px"  prop="max_salary_range">
              <el-input v-model.number="occupationForm.max_salary_range" size="medium" type="number" :min="0"
                :max="10000" oninput="value=value.replace(/[^\d]/g,'')" class="elhide">
                <i slot="suffix">k</i>
              </el-input>
            </el-form-item>
          </el-col>         
        </el-row> -->

        <el-form-item label="薪资范围：" prop="min_salary_range">
          <el-col :span="3">
            <el-input v-model.number="occupationForm.min_salary_range" size="medium"
                style="width:100%" class="elhide" placeholder="最小薪资" value = occupationForm.min_salary_range>
                <i slot="suffix">k</i>
            </el-input>
          </el-col>
          <el-col class="line" :span="1">-</el-col>
          <el-col :span="3">           
            <el-input v-model.number="occupationForm.max_salary_range" size="medium"
              style="width:100%" class="elhide" placeholder="最大薪资">
              <i slot="suffix">k</i>
            </el-input>
          </el-col>
          <el-col :span="3">
            <div class="but">
              <el-button size="medium" type="primary" plain @click="range()">面议</el-button>
            </div>
          </el-col>
        </el-form-item>


        <el-form-item label="职位福利：" class="welfare" prop="welfare">
          <el-tag v-for="(item, index) in welfareTags" :key="index" @click="changeWelfareTags(item, index)"
            :class="occupationForm.welfare.indexOf(index) != -1 ? 'active' : ''">
            {{ item }}
          </el-tag>
        </el-form-item>
        <el-form-item label="工作地点：">
          <el-cascader v-model="occupationForm.city_id" :options="addressOptions" size="medium"
            :props="{ value: 'city_id', label: 'label', children: 'children' }" @change="changeAddress" ref="cascader"
            clearable>
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址：" prop="place_of_work">
          <el-input v-model="occupationForm.place_of_work" placeholder="请输入详细地址" size="medium"></el-input>
        </el-form-item>
        <el-form-item class="f-btn clearfix">
          <el-button type="info" size="medium" @click="publishOccupation()">发布</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 普通企业弹出框 -->
    <!-- <el-dialog :visible.sync="dialogVisible" width="30%" center :show-close="false" v-if="loginStatus==2">
      <span slot="title">
        <i class="fa fa-info-circle" style="color: #e1251b; font-size: 18px"></i>
        填写完公司资料后，才能发布职位</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="medium ">取 消</el-button>
        <el-button type="info" size="medium" @click="$router.push('/pc/company')">填写资料</el-button>
      </span>
    </el-dialog> -->
    <!-- 特许经营弹出框 -->
    <!-- <el-dialog :visible.sync="dialogVisible" width="30%" center :show-close="false" v-if="loginStatus==1">
      <span slot="title">
        <i class="fa fa-info-circle" style="color: #e1251b; font-size: 18px"></i>
        需特许经营资质申请通过后，才能发布职位</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="medium ">取 消</el-button>
        <el-button type="info" size="medium" @click="$router.push('/pc/franchise')">填写资料</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
  import {
    addOccupationInitApi,
    addOccupationListApi,
    showEditOccupationList,
    editOccupationListApi
  } from '@/api/occupation'
  import {
    mapState
  } from 'vuex'
  export default {
    name: "addOccupation",
    data() {
      var validateMaxSalary = (rule, value, callback) => {
        const minSalary = this.occupationForm.min_salary_range;
        if (value && minSalary && value < minSalary) {
          callback(new Error('最高薪资不能小于最低薪资'));
        } else {
          callback();
        }
      };
      return {
        o_id: '', //新添或编辑获取的id
        occupationForm: {
          // recruit_type: "",
          job_name: "",
          allocation: "",
          job_num: "",
          job_description: "",
          // education: "",
          // work_exp: "",
          //   salary_range: "",
          welfare: [],
          place_of_work: "",
          city_pro: '',
          city_town: '',
          city_id: "",
          min_salary_range: '',
          max_salary_range: '',
        },
        city_ids: '',
        dialogVisible: false,
        isShowInfo: true,
        occupationRules: {
          recruit_type: [{
            required: true,
            message: '请选择招聘类型',
            trigger: 'change'
          }],
          job_name: [{
              required: true,
              message: '请输入职位名称',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 20,
              message: '长度在 3 到 20 个字符',
              trigger: 'blur'
            }
          ],
          //   job_type: [{
          //     required: true,
          //     message: '请输入职业类型',
          //     trigger: 'blur'
          //   }],
          job_num: [{
            required: true,
            message: '请输入招聘人数',
            trigger: 'blur'
          }],
          job_description: [{
              required: true,
              message: '请输入职位描述',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 5000,
              message: '长度在 3到 5000 个字符',
              trigger: 'blur'
            }
          ],
          // education: [{
          //   required: true,
          //   message: '请选择学历',
          //   trigger: 'change'
          // }],
          // work_exp: [{
          //   required: true,
          //   message: '请选择工作经验',
          //   trigger: 'change'
          // }],
          //   salary_range: [{
          //     required: true,
          //     message: '请输入薪资',
          //     trigger: 'blur'
          //   }],
          place_of_work: [{
            required: true,
            message: '请输入详细地址',
            trigger: 'change'
          }],
          welfare: [{
            required: true,
            message: '请选择职位福利类型',
            trigger: 'change'
          }],
          //   city_id:[{
          //     required: true,
          //     message: '请选择工作地点',
          //     trigger: 'change'
          //   }],
          allocation: [{
            required: true,
            message: '请选择岗位职务类型',
            trigger: 'change'
          }],
          // min_salary_range: [{
          //   required: true,
          //   message: '请输入薪资',
          //   trigger: 'blur'
          // }],
          // min_salary_range: [{
          //     required: true,
          //     message: '请输入最低薪资',
          //     trigger: 'blur'
          //   },
          //   {
          //     type: 'number',
          //     min: 0,
          //     max: 10000,
          //     message: '最低薪资必须在0-10000之间',
          //     trigger: 'blur'
          //   },
          // ],
          // max_salary_range: [{
          //     required: true,
          //     message: '请输入最高薪资',
          //     trigger: 'blur'
          //   },
          //   {
          //     type: 'number',
          //     min: 0,
          //     max: 10000,
          //     message: '最高薪资必须在0-10000之间',
          //     trigger: 'blur'
          //   },
          //   {
          //     validator: validateMaxSalary,
          //     trigger: 'blur'
          //   }
          // ],
        },
        selectValue: "",
        name: "",
        inputVisible: false,
        inputValue: '',
        addressOptions: [], //工作地点
        // educationList: {}, //学历
        recruitType: {}, //招聘类型
        salaryRange: {}, //薪资范围
        welfareTags: {},
        // work_exp: {}, //经验
        cascaderValue: "",
        // company_full: "",
        welfareSelect: [],
        allocationList: "", //单位性质
        loading: false,
      }
    },
    computed: {
      ...mapState(['loginStatus'])
    },
    created() {
      this.o_id = this.$route.query.id
      //如果id为0是新添，如果大于0就是编辑
      if (this.o_id == 0) {
        this.getOccupationInitList()
      } else {
        this.editOccupationList(this.o_id)
      }
    },
    methods: {
      //添加初始化数据
      async getOccupationInitList() {
        this.loading = true
        const {
          data: res
        } = await addOccupationInitApi()
        if (res.status == 90000) {
          this.isShowInfo = false
          this.dialogVisible = true
        } else if (res.status == 10000) {
          this.addressOptions = res.result.city
          this.educationList = res.result.education
          this.recruitType = res.result.recruit_type
          //   this.salaryRange = res.result.salary_range
          this.work_exp = res.result.work_exp
          this.allocationList = res.result.allocation
          this.welfareTags = res.result.welfare
        } else {
          return this.$message.error(res.message)
        }
        this.loading = false
      },
      //编辑状态下数据回显
      async editOccupationList(id) {
        this.loading = true
        const {
          data: res
        } = await showEditOccupationList(id)
        this.occupationForm = res.result
        this.occupationForm.allocation = this.occupationForm.allocation + ''
        this.getOccupationInitList()
        if (this.occupationForm.min_salary_range == 0) {
          this.occupationForm.min_salary_range = '面议';
        }
        if (this.occupationForm.max_salary_range == 0 || this.occupationForm.max_salary_range === 1) {
          this.occupationForm.max_salary_range = '面议';
        }
        this.loading = false
      },
      changeWelfareTags(item, index) {
        //点击选中再次点击取消选中
        //在data中创建 selectArr : [ ]   数组  “selectColor”是选中后的文字样式
        //使用selectArr 查询数据reasons的索引是否存在，如果不存在添加在selectArr数组中
        //如果已经存在了就说明当前选项是已经被选中了，就从数组中删去
        //使用indexOf方法和splice
        let indexItem = this.occupationForm.welfare.indexOf(index)

        if (indexItem == -1) {
          this.occupationForm.welfare.push(index);
        } else {
          this.occupationForm.welfare.splice(indexItem, 1);
        }
      },
      //获取城市id
      changeAddress(val) {
        this.occupationForm.city_pro = val[0]
        this.occupationForm.city_town = val[1]
        this.occupationForm.city_id = val[2]
      },
      //发布职位数据提交
      publishOccupation() {
        this.$refs.occupationRefs.validate(async (valid) => {
          if (!valid) return;
          //添加功能
          if (this.o_id == 0) {
            const {
              data: res
            } = await addOccupationListApi(this.occupationForm)
            if (res.status !== 10000) {
              return this.$message.error(res.message)
            }
            this.$message.success('添加职位成功')
          } else {
            //编辑状态下提交
            const {
              data: res
            } = await editOccupationListApi(this.occupationForm)
            if (res.status !== 10000) {
              return this.$message.error(res.message)
            }
            this.$message.success('修改职位成功')
          }
          this.$router.go(-1)
        })
      },

      range(){       
        this.occupationForm.min_salary_range = '面议';
        this.occupationForm.max_salary_range = '面议';
      }
    },
  }

</script>

<style lang="less" scoped>
  .add-occupation {
    .c-title {
      height: 40px;
      margin-bottom: 20px;
      border-bottom: 2px solid #e4e7ed;
      display: flex;
      justify-content: space-between;

      .t-text {
        line-height: 40px;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .m-container {
      background: #fff;
      padding: 20px;
    }


    .c-list {
      display: flex;

      .l-img {
        background-image: url("../../assets/images/one.png");
        padding-top: 10px;
        padding-bottom: 17px;
        padding-left: 39px;
        background-repeat: no-repeat;
        background-size: 17px 24px;
        // background-position: 10px 16px;
      }

      .l-imgTwo {
        background-image: url("../../assets/images/two.png");
        padding-top: 10px;
        padding-bottom: 17px;
        padding-left: 39px;
        background-repeat: no-repeat;
        background-size: 17px 24px;
        // background-position: 10px 16px;
      }

      .l-info {
        h3 {
          font-size: 18px;
          font-weight: 500;
          color: rgb(34, 34, 34);
          line-height: 25px;
        }

        p {
          font-size: 12px;
          font-weight: 400;
          color: rgb(117, 117, 117);
          line-height: 17px;
        }
      }
    }

    .el-input {
      width: 50%;
    }

    .el-select {
      width: 50%;
    }

    /deep/.el-textarea__inner {
      width: 50%;
    }

    /deep/ .el-input__count {
      bottom: 2%;
      right: 51%;
    }

    .f-btn {
      margin-top: 40px;
      width: 54%;

      .el-button {
        float: right;
      }
    }

    .f-item {
      width: 60%;
    }

    .el-tag {
      margin-right: 10px;
      background: #fff;
      border: 1px solid #d9d9d9;
      color: rgba(0, 0, 0, 0.65);
    }

    .button-new-tag {
      width: 70px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 13px;
    }

    .input-new-tag {
      width: 70px;
      vertical-align: bottom;
    }

    .el-cascader {
      width: 50%;
    }

    .active {
      background-color: #e5f4ff;
      color: #0092ff;
    }
  }

  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  .welfare {
    width: 60%;
  }

  .company-name {
    font-size: 16px;
    font-weight: 500;
  }

  /deep/.el-dialog__wrapper {
    top: 10%;
  }

  /deep/.el-dialog__body {
    padding: 0;
  }

  .but{
    margin-left: 20px;
  }
  .line{
    text-align: center;
  }

</style>
